import React, { useRef, useState } from "react";
import { useCustomerDataBaseContext } from "../context/CustomerDataBaseProvider";
import { Button, Dropdown, MenuProps } from "antd";
import { triggerNotification } from "../../../component/Toster";

const CustomerDatabaseHeader = () => {
  const { searchValue, handleSearch, csvUploadAPI, loader, syncAPI } = useCustomerDataBaseContext();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [csvType, setCSVType] = useState<string>("");
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] ?? null;
    if (selectedFile) {
      const { type } = selectedFile;
      const isCSV = type === "text/csv";
      if (isCSV) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        csvUploadAPI(formData, csvType);
      } else {
        triggerNotification("error", "", "Please upload only CSV files", "topRight", "pop-color");
      }

      e.target.value = "";
    }
  };
  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className=" w-[170px]  py-3 custom-dropdown">
          <div className="flex w-full flex-col">
            <button
              onClick={() => {
                setCSVType("google");
                triggerFileInput();
              }}
              className="csv-upload-options"
            >
              Google CSV
            </button>
            <button
              onClick={() => {
                setCSVType("zoho");
                triggerFileInput();
              }}
              className="csv-upload-options"
            >
              Zoho CSV
            </button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="...">

      <div className="al-ser1">
        <div>
          <div className="flex px-2 py-2 rounded-md border-2 int w-full	 overflow-hidden  font-[sans-serif]">
            <svg width="18" height="26" viewBox="0 0 24 24" fill="none" className=" w-[14px] xl:w-[18px] h-[18px] xl:h-[26px]" xmlns="http://www.w3.org/2000/svg">
              <g id="vuesax/linear/search-normal">
                <g id="search-normal">
                  <path
                    id="Vector"
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path id="Vector_2" d="M22 22L20 20" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </g>
              </g>
            </svg>

            <input type="text" placeholder="Search" onChange={handleSearch} value={searchValue} className="w-full outline-none bg-transparent text-gray-600 text-[11px] xl:text-[14px] search-input" />
          </div>
        </div>
        <div>
          <button className="n-user flex align-center text-[14px]" type="button" onClick={syncAPI}>
            <span className="material-symbols-outlined mr-1">
              sync
            </span>  Sync
          </button>
        </div>
        <div className="mx-2">
          <Dropdown menu={{ items }} trigger={["click"]}>
            <Button className=" add-btn"><span className="material-symbols-outlined mr-1">
              cloud_upload
            </span> Upload CSV</Button>
          </Dropdown>

          {/* <button className="n-user flex align-center text-[14px]" type="button" onClick={triggerFileInput}>
            Upload CSV
          </button> */}
          <input className="hidden" ref={fileInputRef} type="file" onChange={handleFileChange} />
        </div>
      </div>
      {/* {loader && <Loader />} */}
    </div>
  );
};

export default CustomerDatabaseHeader;
