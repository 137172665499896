export const ProductPricelist: DataItem[] = [
  {
    id: "1",
    category: "business",
    type: "starter",
    price_type: "below_20",
    starter: "1642.80",
    standard: "8832.00",
    plus: "16560.00",
  },
  {
    id: "2",
    category: "enterprise",
    type: "starter",
    price_type: "below_20",
    starter: "8400.00",
    standard: "20040.00",
    plus: "25056.00",
  },
  {
    id: "3",
    category: "frontline",
    type: "basic",
    price_type: "below_20",
    starter: "4560.00",
    standard: "4560.00",
    plus: null,
  },
  {
    id: "4",
    category: "business",
    type: "starter",
    price_type: "above_20",
    starter: "2760.00",
    standard: "11040.00",
    plus: "16560.00",
  },
  {
    id: "5",
    category: "enterprise",
    type: "starter",
    price_type: "above_20",
    starter: "8400.00",
    standard: "20040.00",
    plus: "25056.00",
  },
  {
    id: "6",
    category: "frontline",
    type: "basic",
    price_type: "above_20",
    starter: "4560.00",
    standard: "4560.00",
    plus: null,
  },
];

interface PriceDetails {
  starter: string | number;
  standard: string | number;
  plus?: any;
}

interface PriceObject {
  business: PriceDetails;
  enterprise: PriceDetails;
  frontline: Omit<PriceDetails, "plus">;
}

interface DataItem {
  id: string;
  type: string;
  price_type: "below_20" | "above_20";
  category: "business" | "enterprise" | "frontline";
  starter: string;
  standard: string;
  plus?: any;
}

export const setProductPriceDetails = (data: DataItem[]) => {
  let first_20_price: PriceObject = {
    business: { starter: "", standard: "", plus: "" },
    enterprise: { starter: "", standard: "", plus: "" },
    frontline: { starter: "", standard: "" },
  };

  let after_20_price: PriceObject = {
    business: { starter: "", standard: "", plus: "" },
    enterprise: { starter: "", standard: "", plus: "" },
    frontline: { starter: "", standard: "" },
  };

  // Populate the price objects
  data.forEach((item) => {
    if (item.price_type === "below_20") {
      if (item.category in first_20_price) {
        first_20_price[item.category].starter = parseFloat(item.starter);
        first_20_price[item.category].standard = parseFloat(item.standard);
        if (item.category !== "frontline") {
          first_20_price[item.category].plus = item.plus !== null ? parseFloat(item.plus) : "";
        }
      }
    } else if (item.price_type === "above_20") {
      if (item.category in after_20_price) {
        after_20_price[item.category].starter = parseFloat(item.starter);
        after_20_price[item.category].standard = parseFloat(item.standard);
        if (item.category !== "frontline") {
          after_20_price[item.category].plus = item.plus !== null ? parseFloat(item.plus) : "";
        }
      }
    }
  });

  // Return the populated price objects
  return { first_20_price, after_20_price };
};

export const subplans: any = {
  business: ["Starter", "Standard", "Plus"],
  enterprise: ["Starter", "Standard", "Plus"],
  frontline: ["Basic", "Plus"],
  akku: ["Plan 1", "Plan 2", "Plan 3", "Plan 4"],
};

// export const populateSubplans = (productType: any) => {
//     // const subplanDropdown = subplans[productType].map((subplan: any, index: any) => (
//     //     <option key={index} value={subplan.toLowerCase()}>
//     //         {subplan}
//     //     </option>
//     // ));
//     // return subplanDropdown;
//     return subplans[productType];
// };

export const capitalizeFirstLetter = (string: any) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
