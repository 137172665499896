import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { CalculatorOutlined, DatabaseOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import "./sideBar.scss";
import { retrieveData, storeData } from "../../services/storage/Storage";

const { Sider } = Layout;

type MenuItem = {
  key: string;
  icon: React.ReactNode;
  label: React.ReactNode;
  to: string;
  disabled: boolean;
};

const SideBar: React.FC = () => {
  const activeKey = retrieveData("sideBarKey", true);
  const roleId = sessionStorage.getItem("userRoleId");
  const [userRoleId, setUserRoleId] = useState<string | null>(null);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState<string>(activeKey ?? "1");
  const navigate = useNavigate();

  useEffect(() => {
    if (roleId) {
      setUserRoleId(roleId);
    }
  }, [roleId]);
  useEffect(() => {
    const storedKey = retrieveData("sideBarKey", true) ?? "1";
    setSelectedKey(storedKey);
  }, [userRoleId]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getDisabled(menu: string): boolean {
    const accessMap: Record<string, string[]> = {
      "1": ["/invoice_logs", "/customer-database", "/hubspot"], // All menus enabled
      "2": ["/invoice_logs"], // Only GWS Calculator
      "3": ["/invoice_logs", "/hubspot"], // GWS Calculator & HubSpot
      "4": ["/customer-database"], // Only Customer Database
    };
    return !accessMap[Number(userRoleId)]?.includes(menu);
  }

  function getItem(label: React.ReactNode, key: string, icon?: React.ReactNode, to?: string): MenuItem {
    return {
      key,
      icon,
      label,
      to: to ?? "",
      disabled: getDisabled(to ?? ""),
    };
  }

  const items = [
    getItem("GWS Calculator", "1", <CalculatorOutlined style={{ color: "#000" }} />, "/invoice_logs"),
    getItem("Customer Database", "2", <DatabaseOutlined style={{ color: "#000" }} />, "/customer-database"),
    getItem("HubSpot", "3", <span className="material-symbols-outlined !text-[#000]">network_node</span>, "/hubspot"),
  ];

  useEffect(() => {
    if (activeKey) {
      const enabledKeys = items.filter((item) => !item.disabled).map((item) => item.key);
      setSelectedKey(enabledKeys.includes(activeKey) ? activeKey : enabledKeys[0] || "1");
      // setSelectedKey(activeKey);
    }
  }, [activeKey]);

  const handleResize = () => {
    const deviceWidth = window.innerWidth <= 1930;
    setCollapsed(deviceWidth);
  };
  const handleMenuClick = (e: { key: string }) => {
    setSelectedKey(e.key);
    storeData("sideBarKey", e.key, true);
    const item = items.find((item: { key: string }) => item.key === e.key);
    if (item) {
      navigate(item.to);
    }
  };

  const handleClickToggle = () => {
    const toggle = document.getElementsByClassName("ant-layout-sider-trigger")[0] as HTMLElement;
    toggle.click();
  };

  const handleCollapse = (value: boolean) => {
    setCollapsed(value);
  };

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={(value) => handleCollapse(value)} className={!collapsed ? "isNotActiveCollapsed" : "activeCollapsed"}>
      <div className={`flex w-full menu-btn ${collapsed ? "justify-center" : " justify-start"}`}>
        <span className="material-symbols-outlined   menu-icon cursor-pointer text-[#bd44ca]" onClick={handleClickToggle}>
          {collapsed ? "chevron_right" : "chevron_left"}
        </span>
      </div>

      <Menu
        theme="dark"
        selectedKeys={[selectedKey]}
        mode="inline"
        onClick={(e) => {
          const clickedItem = items.find((item) => item.key === e.key);
          if (!clickedItem?.disabled) {
            handleMenuClick(e);
          }
        }}
      >
        {items.map((item) => (
          <Menu.Item key={item.key} icon={item.icon} className={`${item?.disabled ? "!cursor-not-allowed  disabled-menu bg-[#fff]" : ""}`}>
            <Link to={!item?.disabled ? item.to : "#"}>{item.label}</Link>
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};

export default SideBar;
