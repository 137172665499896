import React from "react";
import { Table, Tooltip } from "antd";
import { useHubSpotDealCreateContext } from "./context/HubSpotDealCreateProvider";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import HubSpotDealCreateHeader from "./header/HubSpotDealCreateHeader";
import "./CustomerDatabase.scss";
import generateTitle from "../generateTitle";
const HubSpotDeals = () => {
  const { hubSpotDealsData, loader, sizeChanger, currentPage, totalRecords, setSizeChanger, setCurrentPage, setIsBefore, setIsAfter } = useHubSpotDealCreateContext();
  const columns = [
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "S.No",
          sortColumns?.find(({ column }: any) => column.key === "key"),
        ),
      dataIndex: "sno",
      key: "sno",
      width: "60px",
      render: (text: any, record: any) => <p> {text}</p>,
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Deal Name",
          sortColumns?.find(({ column }: any) => column.key === "key"),
        ),
      dataIndex: "dealName",
      key: "dealName",
      width: "350px",
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <p className="plan-names"> {text}</p>
        </Tooltip>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Pipenline",
          sortColumns?.find(({ column }: any) => column.key === "key"),
        ),
      dataIndex: "pipeline",
      key: "pipeline",
      width: "200px",
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <p className="plan-names"> {text}</p>
        </Tooltip>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Deal stage",
          sortColumns?.find(({ column }: any) => column.key === "key"),
        ),
      dataIndex: "dealStage",
      key: "dealStage",
      width: "auto",
      render: (text: any, record: any) => <p className={text === "-" ? "flex  justify-center" : "flex justify-start"}> {text}</p>,
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Created Date",
          sortColumns?.find(({ column }: any) => column.key === "key"),
        ),
      dataIndex: "createdDate",
      key: "createdDate",
      width: "auto",
      render: (text: any, record: any) => <p className={text === "-" ? "flex  justify-center" : "flex justify-start"}> {text}</p>,
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Close Date",
          sortColumns?.find(({ column }: any) => column.key === "key"),
        ),
      dataIndex: "closeDate",
      key: "closeDate",
      width: "auto",
      render: (text: any, record: any) => <p className={text === "-" ? "flex  justify-center" : "flex justify-start"}> {text}</p>,
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Amount",
          sortColumns?.find(({ column }: any) => column.key === "key"),
        ),
      dataIndex: "amount",
      key: "amount",
      width: "auto",
      render: (text: any, record: any) => <p className={text === "-" ? "flex  justify-center" : "flex justify-start"}> {text}</p>,
    },
  ];
  const handlePageChange = (page: number, type: any) => {
    setCurrentPage((page - 1) * sizeChanger);
  };
  const handlePageSizeChange = (current: number, newSize: number) => {
    setCurrentPage(0);
    setSizeChanger(newSize);
  };

  const itemRender = (_: any, type: any) => {
    if (type === "prev") {
      return (
        <div
          role="none"
          onClick={() => {
            setIsBefore(true);
            setIsAfter(false);
          }}
          className="text-[#bd44ca] hover:bg-gray-300 cursor-pointer bg-gray-200 rounded-[50%]"
        >
          <LeftOutlined />
        </div>
      );
    }
    if (type === "next") {
      return (
        <div
          role="none"
          onClick={() => {
            setIsBefore(false);
            setIsAfter(true);
          }}
          className="text-[#bd44ca] hover:bg-gray-300 cursor-pointer bg-gray-200 rounded-[50%]"
        >
          <RightOutlined />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="  mx-auto   w-[97%] xl:w-[97%] 2xl:w-[95%] mt-[40px]	customer-data-base relative">
      <div className="bg-gy pt-3">
        <div className="flex justify-between gap-6 align-center mb-5">
          <div>
            <h3>Hubspot Deals</h3>
          </div>
          <HubSpotDealCreateHeader />
        </div>
        <Table
          columns={columns}
          dataSource={hubSpotDealsData}
          loading={loader}
          pagination={{
            current: currentPage / sizeChanger + 1,
            total: totalRecords,
            showSizeChanger: true,
            pageSizeOptions: ["25", "50", "100"],
            defaultPageSize: sizeChanger,
            pageSize: sizeChanger,
            onChange: handlePageChange,
            itemRender: itemRender,
            onShowSizeChange: handlePageSizeChange,
          }}
        />
      </div>
    </div>
  );
};

export default HubSpotDeals;
