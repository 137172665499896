import * as Yup from "yup";

export const hubspotDealValidationSchema = Yup.object().shape({
  dealName: Yup.string().required("Deal Name is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(/^\d*\.?\d*$/, "Amount must be a valid number"),
  closeDate: Yup.string().required("Close Date is required"),
  dealOwner: Yup.string().required("Deal Owner is required"),
  pipeline: Yup.string().required("Pipeline is required"),
  dealStage: Yup.string().required("Deal Stage is required"),
  dealSource: Yup.string().required("Deal Source is required"),
  contact: Yup.string().required("Contact is required"),
});

export const dealSourceList = [
  { label: "Google Ads", value: "Google Ads" },
  { label: "Email Campaign", value: "Email Campaign" },
  { label: "Existing Customer", value: "Existing Customer" },
  { label: "Referral", value: "Referral" },
  { label: "Channel Partner", value: "Channel Partner" },
  { label: "Direct Google Sales Team", value: "Direct Google Sales Team" },
  { label: "Outbound", value: "Outbound" },
  { label: "Organic", value: "Organic" },
];

export const constructPayload = (searchValue: string, contactLimit: number) => {
  return {
    filterGroups: [
      {
        filters: [
          {
            operator: "CONTAINS_TOKEN",
            propertyName: "firstname",
            value: searchValue,
          },
        ],
      },
      {
        filters: [
          {
            operator: "CONTAINS_TOKEN",
            propertyName: "lastname",
            value: searchValue,
          },
        ],
      },
      {
        filters: [
          {
            operator: "CONTAINS_TOKEN",
            propertyName: "email",
            value: searchValue,
          },
        ],
      },
    ],
    limit: contactLimit,
  };
};

export const constructPayloadForSubmit = (values: any) => {
  console.log("values:", values);
  return {
    associations: [
      {
        to: {
          id: values?.contact,
        },
        types: [
          {
            associationCategory: "HUBSPOT_DEFINED",
            associationTypeId: 3,
          },
        ],
      },
    ],
    properties: {
      dealname: values?.dealName,
      amount: values?.amount,
      closedate: values?.closeDate,
      pipeline: values?.pipeline,
      dealstage: values?.dealStage,
      hubspot_owner_id: values?.dealOwner,
      deal_source: values?.dealSource,
    },
  };
};
