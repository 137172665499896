import React from "react";
import "./index.css";
import "./App.scss";
import Login from "./login/Login";

import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Logs from "./pages/Logs";
import Newproposal from "./pages/Newproposal";
import UserManagement from "./pages/UserManagement";
import Quotes from "./pages/Quotes";
import BeforeLoginLayout from "./BeforeLoginLayout";
import AfterLoginLayout from "./AfterLoginLayout";
import CustomerDataBaseWrapper from "./pages/customer-database/context/CustomerDataBaseProvider";
import HubSpotDealCreateProvider from "./pages/hubspot-deal-create/context/HubSpotDealCreateProvider";
import HubSpotDealCreate from "./pages/hubspot/HubspotDealCreate";

const App = () => {
  return (
    <Routes>
      <Route element={<BeforeLoginLayout />}>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route element={<AfterLoginLayout />}>
          <Route path="/invoice_logs" element={<Logs />} />
          <Route path="/proposal" element={<Newproposal />} />
          <Route path="/usermanagement" element={<UserManagement />} />
          <Route path="/Quotes" element={<Quotes />} />
          <Route path="/customer-database" element={<CustomerDataBaseWrapper />} />
          <Route path="/hubspot" element={<HubSpotDealCreateProvider />} />
          <Route path="/hubspot/create" element={<HubSpotDealCreate />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
