import React, { useState } from "react";
import { Layout } from "antd";
import SideBar from "./component/SideBar/SideBar";
import { Outlet } from "react-router-dom";
import Header from "./component/Header";

const AfterLoginLayout: React.FC = () => {
  return (
    <div>
      <Header />
      <Layout style={{ minHeight: "calc(100vh - 88px)" }}>
        <SideBar />
        <Layout.Content style={{ height: "calc(100vh - 88px)", overflowY: "auto" }}>
          <Outlet />
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default AfterLoginLayout;
