import React, { createContext, useState, useMemo, useRef, useEffect } from "react";
import axios from "axios";
import dayjs from 'dayjs';

import { triggerNotification } from "../../../component/Toster";
import { debounce } from "lodash";
import { CustomerDataBaseContextType, getYearMonth } from "../helper/CustomerDataBaseContextHeper";
import { retrieveData, storeData } from "../../../services/storage/Storage";

export const CustomerDataBaseContext = createContext<CustomerDataBaseContextType | undefined>(undefined);

type ThresholdListType = {
  data?: {
    negativeArpuRecords?: any[];
  };
};

export const CustomerDataBaseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const today = dayjs();
  const previousMonthStart = today.subtract(1, 'month').startOf('month');
  const previousMonthEnd = today.subtract(1, 'month').endOf('month');

  const startDate = previousMonthStart.format('YYYY-MM-DD');
  const endDate = previousMonthEnd.format('YYYY-MM-DD');

  const baseUrl = process.env.REACT_APP_CUSTOMER_DATABASE_BASEURL;
  const [searchValue, setSearchValue] = useState<string>("");
  const [customerData, setCustomerData] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [sizeChanger, setSizeChanger] = useState<number>(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [sortBy, setSortBy] = useState("domainName");
  const [sortDirection, setSortDirection] = useState("ASC");
  const [billingMonthStartEndDate, setBillingMonthStartEndDate] = useState([startDate, endDate]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [dateRange, setDateRange] = useState<any>(null);
  const [selectTab, setSelectTab] = useState("all");
  const [thresholdList, setThresholdList] = useState<ThresholdListType>({});

  const [billingPeriod, setBillingPeriod] = useState({
    billingMonthStart: "",
    billingMonthEnd: "",
  });


  useEffect(() => {
    getMissingList(selectTab, billingPeriod.billingMonthStart, billingPeriod.billingMonthEnd, currentPage, pageSize);
  }, []);


  const getMissingList = async (type = "all", billingMonthStart = "", billingMonthEnd = "", currentPage: any, pageSize: number, retryCount = 0) => {
    const failedRetrieveList = retrieveData("failedRetrieveList", true)

    const reset = selectTab === type ? false : true;
    setLoader(true);
    const apiURL = `${baseUrl}customer/dashboard/details`;
    const previousMonthNumber = failedRetrieveList ? 2 : 1
    const previousMonth = dayjs().subtract(previousMonthNumber, "month");
    let monthStart = previousMonth.startOf("month").format("YYYY-MM-DD");
    let monthEnd = previousMonth.endOf("month").format("YYYY-MM-DD");

    const _sortDirection = sortDirection === "ASC" ? "DESC" : "ASC";
    const payload = {
      type,
      searchValue: reset ? "" : searchValue,
      startValue: reset ? 1 : currentPage,
      limitValue: reset ? 10 : pageSize,
      sortBy: reset ? "domainName" : sortBy,
      sortDirection: reset ? "ASC" : _sortDirection,
      billingMonthStart: billingMonthStart || monthStart,
      billingMonthEnd: billingMonthEnd || monthEnd,
      recordCreatedDate: billingMonthStart || monthStart,

    };

    try {
      const response = await axios.post(apiURL, payload, {
        headers: { "Content-Type": "application/json" },
      });
      if (!response?.data?.data && retryCount < 1) {
        storeData("failedRetrieveList", true, true);
        await getMissingList(type, billingMonthStart, billingMonthEnd, currentPage, pageSize, retryCount + 1);
      } else {
        setThresholdList({ ...response.data });
      }
    } catch (err) {
      triggerNotification("error", "", "Error fetching data", "topRight", "pop-color");
    } finally {
      setLoader(false);
    }
  };





  const csvUploadAPI = async (formData: FormData, csvType: string): Promise<void> => {
    const apiUrl = `${baseUrl}${csvType === "google" ? "gws" : "zoho"}/invoice/upload${csvType === "google" ? `?billingMonth=${getYearMonth()}` : ""}`;

    try {
      setLoader(true);

      const response = await axios.post(apiUrl, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const message = response?.data?.message || "CSV uploaded successfully.";
      triggerNotification("success", "", message, "topRight", "pop-color");
    } catch (err: any) {
      const errorMessage = err?.response?.data?.message || "Error uploading CSV. Please try again later.";
      triggerNotification("error", "", errorMessage, "topRight", "pop-color");
    } finally {
      setLoader(false);
    }
  };

  const syncAPI = async () => {
    const apiURL = `${baseUrl}customer/margin`;
    setLoader(true);
    try {
      const response: any = await axios.post(apiURL);
      setLoader(false);
      triggerNotification("success", "", response?.data?.message, "topRight", "pop-color");
      getMissingList(selectTab, billingPeriod.billingMonthStart, billingPeriod.billingMonthEnd, currentPage, pageSize);
    } catch (err) {
      setLoader(false);
      triggerNotification("error", "", "Error fetching data", "topRight", "pop-color");
    }
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchVal = event?.target?.value?.trim();
    setSearchValue(searchVal);
    debouncedSearch(searchVal);
  };




  const debouncedSearch = useMemo(
    () =>
      debounce((searchVal: string) => {
        getMissingList(selectTab, billingPeriod.billingMonthStart, billingPeriod.billingMonthEnd, currentPage, pageSize);
      }, 800),
    [],
  );

  const contextValue = useMemo(
    () => ({
      searchValue,
      setSearchValue,
      handleSearch,
      fileInputRef,
      customerData,
      sizeChanger,
      currentPage,
      totalRecords,
      setPageSize,
      pageSize,
      setSizeChanger,
      setCurrentPage,
      setTotalRecords,
      csvUploadAPI,
      syncAPI,
      setSortBy,
      setSortDirection,
      setBillingMonthStartEndDate,
      billingMonthStartEndDate,
      dateRange,
      billingPeriod,
      selectTab,
      thresholdList,
      loader,
      setDateRange,
      setBillingPeriod,
      setSelectTab,
      getMissingList,
    }),
    [searchValue, customerData, sizeChanger, currentPage, loader, sortBy, sortDirection, billingMonthStartEndDate, setBillingMonthStartEndDate],
  );

  return <CustomerDataBaseContext.Provider value={contextValue}>{children}</CustomerDataBaseContext.Provider>;
};
