import React, { createContext, useState, useMemo, useEffect } from "react";
import axios from "axios";
import { triggerNotification } from "../../../component/Toster";
import { debounce } from "lodash";
import { transformHubSpotDealsData } from "../helper/HubSpotDealCreateContextHelper";

export const HubSpotDealCreateContext = createContext<any | undefined>(undefined);

export const HubSpotDealCreateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const baseUrl = process.env.REACT_APP_CUSTOMER_DATABASE_BASEURL;
  const [searchValue, setSearchValue] = useState<string>("");
  const [hubSpotDealsData, setHubSpotDealsData] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [sizeChanger, setSizeChanger] = useState<number>(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [afterId, setAfterId] = useState(null);
  const [beforeId, setBeforeId] = useState(null);
  const [isAfter, setIsAfter] = useState(false);
  const [isBefore, setIsBefore] = useState(false);

  useEffect(() => {
    setCurrentPage(0);
  }, [sizeChanger]);

  useEffect(() => {
    getCustomerDatabaseList();
  }, [currentPage, sizeChanger]);

  useEffect(() => {
    if (isAfter || isBefore) {
      getCustomerDatabaseList();
    }
  }, [isAfter, isBefore]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchVal = event?.target?.value?.trim();
    setSearchValue(searchVal);
    debouncedSearch(searchVal, sizeChanger);
  };

  const getCustomerDatabaseList = async (search?: string, size?: number) => {
    let apiUrl;
    if (search || searchValue) {
      apiUrl = `${baseUrl}hubspot/deals/search`;
    } else {
      let currentAfterId = null;
      let currentBeforeId = null;
      if (isAfter) {
        currentAfterId = afterId;
        currentBeforeId = null;
      }
      if (isBefore) {
        currentBeforeId = beforeId;
        currentAfterId = null;
      }

      apiUrl = `${baseUrl}hubspot/deals/${size ?? sizeChanger}?after=${currentAfterId}&before=${currentBeforeId}`;
    }
    const payload: object = {
      filterGroups: [
        {
          filters: [
            {
              operator: "CONTAINS_TOKEN",
              propertyName: "dealname",
              value: search ?? searchValue,
            },
          ],
        },
      ],
      limit: size ?? sizeChanger,
    };
    setLoader(true);
    try {
      const response: any = search || searchValue ? await axios.post(apiUrl, payload) : await axios.get(apiUrl);
      const afterId = response?.data?.data?.paging?.next?.after ?? null;
      setAfterId(afterId);
      const beforeId = response?.data?.data?.results[0]?.id ?? null;
      setBeforeId(beforeId);
      const transformedData = transformHubSpotDealsData(response?.data?.data?.results);
      setTotalRecords(response?.data?.data?.results.length);
      setHubSpotDealsData(transformedData);
      setLoader(false);
    } catch (error) {
      triggerNotification("error", "", "Error fetching data", "topRight", "pop-color");
      setLoader(false);
    } finally {
      setIsAfter(false);
      setIsBefore(false);
    }
  };
  const debouncedSearch = useMemo(
    () =>
      debounce((searchVal: string, sizeChanger: number) => {
        getCustomerDatabaseList(searchVal, sizeChanger);
      }, 800),
    [],
  );

  const contextValue = useMemo(
    () => ({
      searchValue,
      setSearchValue,
      handleSearch,
      hubSpotDealsData,
      loader,
      sizeChanger,
      currentPage,
      totalRecords,
      setSizeChanger,
      setCurrentPage,
      setTotalRecords,
      setIsBefore,
      setIsAfter,
    }),
    [searchValue, hubSpotDealsData, sizeChanger, currentPage, loader, isAfter, isBefore],
  );

  return <HubSpotDealCreateContext.Provider value={contextValue}>{children}</HubSpotDealCreateContext.Provider>;
};
