import React, { useEffect, useState } from "react";
import { Button, DatePicker, Table, Tooltip } from "antd";
import { useCustomerDataBaseContext } from "./context/CustomerDataBaseProvider";
import CustomerDatabaseHeader from "./header/CustomerDatabaseHeader";
import "./CustomerDatabase.scss";
import generateTitle from "../generateTitle";
import { SortOrder } from "antd/es/table/interface";
import { CalendarOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const CustomerDatabase = () => {
  const {
    dateRange,
    billingPeriod,
    selectTab,
    thresholdList,
    currentPage,
    pageSize,
    setDateRange,
    setBillingPeriod,
    setSelectTab,
    setCurrentPage,
    setPageSize,
    totalRecords,
    setSortDirection,
    setSortBy,
    loader,
    getMissingList,
  } = useCustomerDataBaseContext();

  const disabledDate = (current: dayjs.Dayjs) => {
    return current >= dayjs().startOf("month");
  };

  const columns = [
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Domain Name",
          sortColumns?.find(({ column }: any) => column.key === "domainName"),
        ),
      dataIndex: "domainName",
      key: "domainName",
      width: "200px",
      sorter: true,
      defaultSortOrder: "ascend" as SortOrder,
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <p className="plan-names"> {text}</p>
        </Tooltip>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Customer Name",
          sortColumns?.find(({ column }: any) => column.key === "customerName"),
        ),
      dataIndex: "customerName",
      key: "customerName",
      width: "200px",
      sorter: true,
      defaultSortOrder: "ascend" as SortOrder,
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <p className="plan-names customer-name"> {text}</p>
        </Tooltip>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Plan Names",
          sortColumns?.find(({ column }: any) => column.key === "planNames"),
        ),
      dataIndex: "planNames",
      key: "planNames",
      width: "200px",
      sorter: true,
      defaultSortOrder: "ascend" as SortOrder,
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <p className="plan-names"> {text}</p>
        </Tooltip>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Plan Type",
          sortColumns?.find(({ column }: any) => column.key === "planType"),
        ),
      dataIndex: "planType",
      key: "planType",
      width: "auto",
      sorter: true,
      defaultSortOrder: "ascend" as SortOrder,
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <p className="plan-names"> {text}</p>
        </Tooltip>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Quantity",
          sortColumns?.find(({ column }: any) => column.key === "quantity"),
        ),
      sorter: true,
      dataIndex: "quantity",
      key: "quantity",
      width: "auto",
      defaultSortOrder: "ascend" as SortOrder,
      render: (text: any, record: any) => (
        // <Tooltip title={text}>
        <p className="plan-names"> {text}</p>
        // </Tooltip>
      ),
    },

    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "GWS Subtotal",
          sortColumns?.find(({ column }: any) => column.key === "key"),
        ),
      dataIndex: "gwsSubtotal",
      key: "gwsSubtotal",
      width: "auto",
      render: (text: any, record: any) => <p className={text === "-" ? "flex  justify-center" : "flex justify-start"}> {text}</p>,
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Zoho Subtotal",
          sortColumns?.find(({ column }: any) => column.key === "key"),
        ),
      dataIndex: "zohoSubtotal",
      key: "zohoSubtotal",
      width: "auto",
      render: (text: any, record: any) => <p className={text === "-" ? "flex  justify-center" : "flex justify-start"}> {text}</p>,
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "ARPU Amount",
          sortColumns?.find(({ column }: any) => column.key === "arpuAmount"),
        ),
      dataIndex: "arpuAmount",
      key: "arpuAmount",
      width: "auto",
      sorter: true,
      defaultSortOrder: "ascend" as SortOrder,
      render: (text: any, record: any) => <p className={text === "-" ? "flex  justify-center" : "flex justify-start"}> {text}</p>,
    },
    {
      title: ({ sortColumns }: any) => (
        <div className="flex gap-2">
          {generateTitle(
            "Billing Month",
            sortColumns?.find(({ column }: any) => column.key === "billingMonth"),
          )}
          <DatePicker
            suffixIcon={<CalendarOutlined style={{ fontSize: "20px" }} />}
            className="custom-date-range-picker"
            allowClear={false}
            picker="month"
            format="YYYY-MM-DD"
            onChange={handleDateChange}
            value={dateRange}
            disabledDate={disabledDate}
            onOk={(dates) => console.log("Selected Date Range:", dates)}
          />
        </div>
      ),
      dataIndex: "billingMonth",
      key: "billingMonth",
      width: "auto",
      render: (text: any, record: any) => <p className={text === "-" ? "flex  justify-center" : "flex justify-start"}> {text}</p>,
    },
  ];
  const googleRecordColumns = [
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Domain Name",
          sortColumns?.find(({ column }: any) => column.key === "domainName"),
        ),
      sorter: true,
      dataIndex: "domainName",
      key: "domainName",
      width: 270,
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <p className="plan-names"> {text || "-"}</p>
        </Tooltip>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Subscription",
          sortColumns?.find(({ column }: any) => column.key === "Subscription"),
        ),
      sorter: true,
      dataIndex: "subscription",
      key: "subscription",
      width: 350,
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <p className="plan-names"> {text || "-"}</p>
        </Tooltip>
      ),
    },
    { title: "Description", dataIndex: "description", key: "description", width: 200, render: (text: any) => text || "-" },
    { title: "Order Name", dataIndex: "orderName", key: "orderName", width: 200, render: (text: any) => text || "-" },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 130,
      render: (date: any) => (date ? dayjs(date).format("YYYY-MM-DD") : "-"),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: 130,
      render: (date: any) => (date ? dayjs(date).format("YYYY-MM-DD") : "-"),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Quantity",
          sortColumns?.find(({ column }: any) => column.key === "quantity"),
        ),
      sorter: true,
      dataIndex: "quantity", key: "quantity", width: 100, render: (text: any) => text || "-"
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Amount",
          sortColumns?.find(({ column }: any) => column.key === "amount"),
        ),
      sorter: true,
      dataIndex: "amount",
      key: "amount",
      width: 180,
      render: (text: any) => text || "-",
    },
    { title: "Customer ID", dataIndex: "customerId", key: "customerId", width: 140, render: (text: any) => text || "-" },
    { title: "SKU ID", dataIndex: "skuId", key: "skuId", width: 140, render: (text: any) => text || "-" },
    // {
    //   title: 'GWS Billing Month',
    //   dataIndex: 'gwsBillingMonth',
    //   key: 'gwsBillingMonth',
    //   width: 150,
    //   render: (date: any) => date ? dayjs(date).format('YYYY-MM-DD') : '-'
    // },
    // {
    //   title: 'CSV Upload Timestamp',
    //   dataIndex: 'csvUploadTimestamp',
    //   key: 'csvUploadTimestamp',
    //   width: 180,
    //   render: (date: any) => date ? dayjs(date).format('YYYY-MM-DD') : '-'
    // },
  ];

  const zohoRecordColumns = [
    { title: "Invoice Date", dataIndex: "invoiceDate", key: "invoiceDate", width: 160 },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Invoice Number",
          sortColumns?.find(({ column }: any) => column.key === "invoiceNumber"),
        ),
      sorter: true,
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: 200,
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Customer Name",
          sortColumns?.find(({ column }: any) => column.key === "customerName"),
        ),
      sorter: true,
      dataIndex: "customerName",
      key: "customerName",
      width: 250,
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <p className="plan-names"> {text}</p>
        </Tooltip>
      ),
    },
    { title: "Due Date", dataIndex: "dueDate", key: "dueDate", width: 150 },
    { title: "Currency", dataIndex: "currencyCode", key: "currencyCode", width: 120 },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      key: "subtotal",
      width: 140,
      render: (text: number) => `₹${text.toFixed(2)}`,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: 130,
      render: (text: number) => `₹${text.toFixed(2)}`,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      width: 120,
      render: (text: number) => `₹${text.toFixed(2)}`,
    },
    {
      title: "E-Invoice Status",
      dataIndex: "einvoiceStatus",
      key: "einvoiceStatus",
      width: 200,
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <p className="plan-names"> {text}</p>
        </Tooltip>
      ),
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      width: 250,
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <p className="plan-names"> {text}</p>
        </Tooltip>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Item Description",
          sortColumns?.find(({ column }: any) => column.key === "itemDescription"),
        ),
      sorter: true,
      dataIndex: "itemDescription",
      key: "itemDescription",
      width: 250,
      render: (text: string) => {
        const fullText = text?.split("\n")?.join(" ");
        return (
          <Tooltip title={fullText}>
            <div className="plan-names">{fullText || "-"}</div>
          </Tooltip>
        );
      },
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Quantity",
          sortColumns?.find(({ column }: any) => column.key === "quantity"),
        ),
      sorter: true,
      dataIndex: "quantity", key: "quantity", width: 100
    },
    {
      title: "Item Price",
      dataIndex: "itemPrice",
      key: "itemPrice",
      width: 120,
      render: (text: number) => `₹${text.toFixed(2)}`,
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Item Total",
          sortColumns?.find(({ column }: any) => column.key === "itemTotal"),
        ),
      sorter: true,
      dataIndex: "itemTotal",
      key: "itemTotal",
      width: 150,
      render: (text: number) => `₹${text.toFixed(2)}`,
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Domain Name",
          sortColumns?.find(({ column }: any) => column.key === "domainName"),
        ),
      sorter: true,
      dataIndex: "domainName",
      key: "domainName",
      width: 220,
      render: (text: any) => text || "-",
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Plan Type",
          sortColumns?.find(({ column }: any) => column.key === "planType"),
        ),
      sorter: true,
      dataIndex: "planType",
      key: "planType",
      width: 130,
    },
  ];

  const arpuColumns = [
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Domain Name",
          sortColumns?.find(({ column }: any) => column.key === "domainName"),
        ),
      sorter: true,
      dataIndex: "domainName",
      key: "domainName",
      width: 200,
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <p className="plan-names"> {text || "-"}</p>
        </Tooltip>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Customer Name",
          sortColumns?.find(({ column }: any) => column.key === "customerName"),
        ),
      sorter: true,
      dataIndex: "customerName",
      key: "customerName",
      width: 220,
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <p className="plan-names"> {text || "-"}</p>
        </Tooltip>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Plan Name",
          sortColumns?.find(({ column }: any) => column.key === "planNames"),
        ),
      sorter: true,
      dataIndex: "planNames",
      key: "planNames",
      width: 250,
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <p className="plan-names"> {text || "-"}</p>
        </Tooltip>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Quantity",
          sortColumns?.find(({ column }: any) => column.key === "quantity"),
        ),
      sorter: true, dataIndex: "quantity", key: "quantity", width: 100, render: (text: any) => text || "-"
    },
    { title: "GWS Subtotal", dataIndex: "gwsSubtotal", key: "gwsSubtotal", width: 130, render: (text: any) => text || "-" },
    { title: "Zoho Subtotal", dataIndex: "zohoSubtotal", key: "zohoSubtotal", width: 130, render: (text: any) => text || "-" },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "ARPU Amount",
          sortColumns?.find(({ column }: any) => column.key === "arpuAmount"),
        ),
      sorter: true,
      dataIndex: "arpuAmount",
      key: "arpuAmount",
      width: 130,
      render: (text: any) => text || "-",
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Plan Type",
          sortColumns?.find(({ column }: any) => column.key === "planType"),
        ),
      sorter: true,
      dataIndex: "planType",
      key: "planType",
      width: 100,
      render: (text: any) => text || "-",
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Billing Month",
          sortColumns?.find(({ column }: any) => column.key === "billingMonth"),
        ),
      sorter: true,
      dataIndex: "billingMonth",
      key: "billingMonth",
      width: 130,
      render: (date: any) => (date ? dayjs(date).format("YYYY-MM-DD") : "-"),
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setCurrentPage(pagination.current || 1);
    setPageSize(pagination.pageSize);
    if (sorter.order) {
      setSortBy(sorter.field);
      setSortDirection(sorter.order === "ascend" ? "ASC" : "DESC"); // Sort direction
    } else {
      setSortBy("domainName"); // Default sort column
      setSortDirection("ASC"); // Default sort direction
    }

    // Call API Once 🚀
    getMissingList(selectTab, billingPeriod.billingMonthStart, billingPeriod.billingMonthEnd, pagination?.current, pagination?.pageSize);
  };

  const handleSelect = (el: string) => {
    setSelectTab(el);
    getMissingList(el, billingPeriod.billingMonthStart, billingPeriod.billingMonthEnd, currentPage, pageSize);
  };

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setBillingPeriod({
        billingMonthStart: date.startOf("month").format("YYYY-MM-DD"),
        billingMonthEnd: date.endOf("month").format("YYYY-MM-DD"),
      });

      getMissingList(selectTab, date.startOf("month").format("YYYY-MM-DD"), date.endOf("month").format("YYYY-MM-DD"), currentPage, pageSize);
    } else {
      setBillingPeriod({ billingMonthStart: "", billingMonthEnd: "" });
    }
  };

  return (
    <div className="  mx-auto   w-[97%] xl:w-[97%] 2xl:w-[95%] mt-[40px]	customer-data-base relative">
      <div className="bg-gy pt-3">
        <div className="flex justify-end items-center mb-5 gap-4 missing-container">
          <Button onClick={() => handleSelect("all")} {...(selectTab === "all" ? { className: "active" } : {})}>
            <span className="material-icons mr-2 text-base">select_all</span>
            <span>All ({thresholdList?.data?.allRecordCount})</span>
          </Button>

          <Button onClick={() => handleSelect("googleRecord")} {...(selectTab === "googleRecord" ? { className: "active" } : {})}>
            <span className="material-icons mr-2 text-base">receipt_long</span>
            <span>Missing Purchases ({thresholdList?.data?.googleRecordCount})</span>
          </Button>
          <Button onClick={() => handleSelect("zohoRecord")} {...(selectTab === "zohoRecord" ? { className: "active" } : {})}>
            <span className="material-icons mr-2 text-base">file_open</span>
            <span>Missing Inv ({thresholdList?.data?.zohoRecordCount})</span>
          </Button>

          <Button onClick={() => handleSelect("negativeARPU")} {...(selectTab === "negativeARPU" ? { className: "active" } : {})}>
            <span className="material-icons mr-2 text-base">trending_down</span>
            <span>Negative ARPU ({thresholdList?.data?.negativeArpuRecordCount})</span>
          </Button>

          <Button onClick={() => handleSelect("belowThresholdARPU")} {...(selectTab === "belowThresholdARPU" ? { className: "active" } : {})}>
            <span className="material-icons mr-2 text-base">warning_amber</span>
            <span>Below ARPU ({thresholdList?.data?.belowThresholdArpuRecordCount})</span>
          </Button>
        </div>
        <div className="flex justify-between gap-6 align-center mb-5">
          <div className="...">
            <h3>Customer Database</h3>
          </div>
          <CustomerDatabaseHeader />
        </div>

        {selectTab === "all" && (
          <Table
            tableLayout="fixed"
            columns={columns}
            dataSource={thresholdList?.data?.marginData}
            loading={loader}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: thresholdList?.data?.allRecordCount,
              showSizeChanger: true,
              pageSizeOptions: ["10", "25", "50", "100"],
            }}
            onChange={handleTableChange}
          />
        )}
        {selectTab === "googleRecord" && (
          <Table
            tableLayout="fixed"
            columns={googleRecordColumns}
            dataSource={thresholdList?.data?.googleRecords}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: thresholdList?.data?.googleRecordCount,
              showSizeChanger: true,
              pageSizeOptions: ["10", "25", "50", "100"],
            }}
            onChange={handleTableChange}
          />
        )}
        {selectTab === "negativeARPU" && (
          <Table
            tableLayout="fixed"
            columns={arpuColumns}
            dataSource={thresholdList?.data?.negativeArpuRecords}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: thresholdList?.data?.negativeArpuRecordCount,
              showSizeChanger: true,
              pageSizeOptions: ["10", "25", "50", "100"],
            }}
            onChange={handleTableChange}
          />
        )}
        {selectTab === "belowThresholdARPU" && (
          <Table
            tableLayout="fixed"
            columns={arpuColumns}
            dataSource={thresholdList?.data?.belowThresholdArpuRecords}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: thresholdList?.data?.belowThresholdArpuRecords,
              showSizeChanger: true,
              pageSizeOptions: ["10", "25", "50", "100"],
            }}
            onChange={handleTableChange}
          />
        )}
        {selectTab === "zohoRecord" && (
          <Table
            tableLayout="fixed"
            columns={zohoRecordColumns}
            dataSource={thresholdList?.data?.zohoRecords}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: thresholdList?.data?.zohoInvoiceNotFoundForGoogleRecordCount,
              showSizeChanger: true,
              pageSizeOptions: ["10", "25", "50", "100"],
            }}
            onChange={handleTableChange}
          />
        )}
      </div>
    </div>
  );
};

export default CustomerDatabase;
