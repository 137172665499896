import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState, useCallback } from "react";
import "./HubspotDeal.scss";
import { Button, DatePicker, Input, Select, Spin } from "antd";
import { Select as AntdSelect } from "antd";
import { triggerNotification } from "../../component/Toster";
import axios from "axios";
import { debounce } from "lodash";
import dayjs from "dayjs";
import { constructPayload, constructPayloadForSubmit, dealSourceList, hubspotDealValidationSchema } from "./helper/HubspotDealHelper";
import { Link, useNavigate } from "react-router-dom";

interface FormValues {
  dealName: string;
  amount: string;
  closeDate: string | null;
  dealOwner: string;
  pipeline: string;
  dealStage: string;
  dealSource: string;
  contact: string;
}

const HubSpotDealCreate = () => {
  const { Option } = Select;
  const navigate = useNavigate();

  const initialValues: FormValues = {
    dealName: "",
    amount: "",
    closeDate: null,
    dealOwner: "",
    pipeline: "",
    dealStage: "",
    dealSource: "",
    contact: "",
  };
  const baseUrl = process.env.REACT_APP_CUSTOMER_DATABASE_BASEURL;
  const [dealOwnersList, setDealOwnersList] = useState<[]>();
  const [pipelineList, setPipelineList] = useState<[]>();
  const [dealStageList, setDealStageList] = useState<any>();
  const [contactsList, setContactsList] = useState<any>();
  const [search, setSearch] = useState<string>("");
  const [contactDropdownVisible, setContactDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactLimit, setContactLimit] = useState<number>(30);
  const [contactsRequestParams, setContactsRequestParams] = useState<string | null>(null);
  const [btnLoader, setBtnLoader] = useState<boolean>(false);

  const debouncedSearch = useCallback(
    debounce(async (searchValue: string, contactsRequestParams: string | null) => {
      setLoading(true);
      const payload = constructPayload(searchValue, contactLimit);
      const apiURL = `${baseUrl}hubspot/contact/search?search=${!!searchValue}&after=${contactsRequestParams}`;

      try {
        const response = await axios.post(apiURL, payload);
        if (response.status === 200) {
          setContactsRequestParams(response?.data?.data?.paging?.next?.after || null);
          setContactsList((prev: any) => ({
            ...response?.data?.data,
            results: [...(prev?.results || []), ...(response?.data?.data?.results || [])],
          }));
        }
      } catch (err) {
        triggerNotification("error", "", "Error fetching data", "topRight", "pop-color");
      } finally {
        setLoading(false);
      }
    }, 800),
    [],
  );

  useEffect(() => {
    getDealOwners();
    getPipeLineDealStagesList();
    debouncedSearch(search, contactsRequestParams);
  }, []);

  const handleSubmit = (values: FormValues) => {
    handleSubmitAPI(values);
  };
  const handleSubmitAPI = async (values: any) => {
    setBtnLoader(true);
    const payload = constructPayloadForSubmit(values);
    const apiURL = `${baseUrl}hubspot/createDeal`;
    try {
      const response = await axios.post(apiURL, payload);
      if (response?.status === 200 || response?.status === 201) {
        triggerNotification("success", "", response?.data?.message, "topRight", "pop-color");
        setBtnLoader(false);
        navigate("/hubspot");
      }
    } catch (err) {
      triggerNotification("error", "", "Error fetching data", "topRight", "pop-color");
      setBtnLoader(false);
    }
  };

  const onChange = (filed: string, value: string, setFieldValue: any) => {
    setFieldValue(filed, value);
  };

  const getDealOwners = async () => {
    const apiURL = `${baseUrl}hubspot/owners`;
    try {
      const response = await axios.get(apiURL);
      if (response.status === 200) {
        setDealOwnersList(response?.data?.data);
      }
    } catch (err) {
      triggerNotification("error", "", "Error fetching data", "topRight", "pop-color");
    }
  };

  const getPipeLineDealStagesList = async () => {
    const apiURL = `${baseUrl}hubspot/dealsandpipelines`;
    try {
      const response = await axios.get(apiURL);
      if (response.status === 200) {
        setPipelineList(response?.data?.data);
      }
    } catch (err) {
      triggerNotification("error", "", "Error fetching data", "topRight", "pop-color");
    }
  };

  const handlePipeLineChange = (field: string, value: string, setFieldValue: any) => {
    setFieldValue(field, value);
    const dealLists: any = pipelineList?.filter((item: any) => item?.id === value);
    if (dealLists.length > 0) {
      setDealStageList(dealLists);
    }
  };

  const handleScroll = (e: any) => {
    const bottom: any = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if (bottom) {
      debouncedSearch(search, contactsRequestParams); // Call debouncedSearch if there's a search term
    }
  };

  const handleSearch = (searchValue: string) => {
    setContactsList([]);
    setSearch(searchValue);
    debouncedSearch(searchValue, contactsRequestParams);
  };

  return (
    <div className="mx-auto w-[97%] xl:w-[97%] 2xl:w-[95%] mt-[40px] hubspot-deal relative">
      <div className="bg-gy pt-3 !p-[15px]">
        <div className="">
          <div className="flex justify-between">
            <button className="flex bck">
              <Link to="/hubspot" className="flex">
                <span className="material-symbols-outlined pr-1 pt-0 xl:pt-[2px] text-[20px] xl:text-[24px]">keyboard_backspace</span>Back
              </Link>
            </button>
            <div>
              <h3>Create HubSpot Deal</h3>
            </div>
          </div>
          <div className="hubspot-form-wrapper bg-[#fff] w-full px-5 mt-2 py-5 rounded">
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={hubspotDealValidationSchema}>
              {({ isSubmitting, setFieldValue, values, handleSubmit, handleChange, errors, resetForm, isValid }) => (
                <Form onSubmit={handleSubmit} className="h-full">
                  <div className="flex flex-col justify-between h-full">
                    <div>
                      <div className="flex gap-10 frm1 min-h-[110px]">
                        <div className="w-[25%]">
                          <label className="block field-label">Deal Name *</label>
                          <Field as={Input} type="text" placeholder="Enter Deal name" className="h-[56px] font-medium text-[18px]" name="dealName" />
                          <ErrorMessage name="dealName" component="div" className="error" />
                        </div>

                        <div className="w-[25%]">
                          <label className="block field-label">Amount *</label>
                          <Field
                            placeholder="Enter Amount"
                            as={Input}
                            type="text"
                            className="h-[56px] font-medium text-[18px]"
                            name="amount"
                            pattern="^\d*\.?\d*$"
                            onInput={(e: any) => {
                              const value = e.target.value;

                              if (value.indexOf(".") !== value.lastIndexOf(".")) {
                                e.target.value = value.slice(0, -1);
                              } else {
                                e.target.value = value.replace(/[^0-9.]/g, "");
                              }
                            }}
                          />

                          <ErrorMessage name="amount" component="div" className="error" />
                        </div>

                        <div className="w-[25%]">
                          <div className="close-date">
                            <label htmlFor="closeDate" className="block field-label">
                              Close Date *
                            </label>
                            <DatePicker
                              id="closeDate"
                              name="closeDate"
                              className="block w-full p-2.5 h-[50px]"
                              format={"DD-MM-YYYY"}
                              onChange={(date, dateString) => {
                                const formattedDate = dayjs(date).endOf("day").toISOString();
                                setFieldValue("closeDate", formattedDate);
                              }}
                              placeholder="Select close date"
                              disabledDate={(current) => current.isBefore(dayjs().subtract(1, "day"))}
                            />
                            <ErrorMessage name="closeDate" component="div" className="text-red-500 text-[12px] xl:text-[15px]" />
                          </div>
                        </div>

                        <div className="w-[25%]">
                          <label className="block field-label">Deal Owner *</label>
                          <Field
                            value={values?.dealOwner || undefined}
                            placeholder="Select Deal Owner"
                            as={Select}
                            className="w-[100%] h-[56px]"
                            name="dealOwner"
                            filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={(value: string) => onChange("dealOwner", value, setFieldValue)}
                          >
                            {dealOwnersList?.map((option: any) => (
                              <Select.Option key={option.id} value={option.id}>
                                {option.firstName} {option?.lastName}
                              </Select.Option>
                            ))}
                          </Field>
                          <ErrorMessage name="dealOwner" component="div" className="error" />
                        </div>
                      </div>

                      <div className="flex gap-10 frm1">
                        <div className="w-[25%]">
                          <label className="block field-label">Pipeline *</label>
                          <Field
                            value={values?.pipeline || undefined}
                            as={AntdSelect}
                            placeholder="Select Pipeline"
                            className="w-[100%] h-[56px]"
                            name="pipeline"
                            filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={(value: string) => handlePipeLineChange("pipeline", value, setFieldValue)}
                          >
                            {pipelineList?.map((option: any) => (
                              <Select.Option key={option.label} value={option.id}>
                                {option.label}
                              </Select.Option>
                            ))}
                          </Field>
                          <ErrorMessage name="pipeline" component="div" className="error" />
                        </div>

                        <div className="w-[25%]">
                          <label className="block field-label">Deal Stage *</label>
                          <div className={values?.pipeline === "" ? "cursor-not-allowed" : ""}>
                            <Field
                              value={values?.dealStage || undefined}
                              placeholder="Select Deal Stage"
                              disabled={values?.pipeline === ""}
                              as={AntdSelect}
                              className="w-[100%] h-[56px]"
                              name="dealStage"
                              filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              onChange={(value: string) => onChange("dealStage", value, setFieldValue)}
                            >
                              {dealStageList?.[0]?.stages?.map((option: any) => (
                                <Select.Option key={option.label} value={option.id}>
                                  {option.label}
                                </Select.Option>
                              ))}
                            </Field>
                          </div>
                          <ErrorMessage name="dealStage" component="div" className="error" />
                        </div>

                        <div className="w-[25%]">
                          <label className="block field-label">Deal Source *</label>
                          <Field
                            value={values?.dealSource || undefined}
                            placeholder="Select Deal Source"
                            as={AntdSelect}
                            className="w-[100%] h-[56px]"
                            name="dealSource"
                            filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={(value: string) => onChange("dealSource", value, setFieldValue)}
                          >
                            {dealSourceList?.map((option: any) => (
                              <Select.Option key={option.label} value={option.label}>
                                {option.label}
                              </Select.Option>
                            ))}
                          </Field>
                          <ErrorMessage name="dealSource" component="div" className="error" />
                        </div>

                        <div className="w-[25%] contact">
                          <label className="block field-label">Contact *</label>
                          <Select
                            showSearch
                            className="h-14 mb-5 custom-dropdown w-full"
                            placeholder="Search contacts"
                            optionLabelProp="label"
                            open={contactDropdownVisible}
                            onChange={(value) => setFieldValue("contact", value)}
                            value={values?.contact || undefined}
                            onDropdownVisibleChange={(visible) => setContactDropdownVisible(visible)}
                            onPopupScroll={handleScroll}
                            onSearch={handleSearch}
                            loading={loading}
                            filterOption={false}
                            notFoundContent={loading ? <Spin size="small" tip="Loading..." /> : contactsList?.results?.length === 0 ? <div className="no-contacts-found">No contacts found</div> : null}
                            allowClear
                            onClear={() => {
                              setContactsList([]);
                              debouncedSearch("", contactsRequestParams);
                            }}
                          >
                            {contactsList?.results?.map((element: any) => (
                              <Option key={element?.properties?.email} value={element?.id} label={element?.properties?.email}>
                                <div>
                                  <div>
                                    {/* {element?.properties?.firstname} {element?.properties?.lastname} */}
                                    {element?.properties?.email}
                                  </div>
                                  {/* <div className="text-gray-400 text-sm">{element?.properties?.email}</div> */}
                                </div>
                              </Option>
                            ))}
                            {loading && (
                              <div>
                                <div className="flex justify-center items-center w-full p-2">
                                  <Spin size="small" tip="Loading..." />
                                </div>
                              </div>
                            )}
                          </Select>
                          <ErrorMessage name="contact" component="div" className="error" />
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <div className="flex justify-end mt-20">
                        <Button loading={btnLoader} disabled={btnLoader} htmlType="submit" className="submit-btn">
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HubSpotDealCreate;
