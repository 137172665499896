interface CustomerData {
  arpuID: string;
  domainName: string;
  planNames: string;
  subscriptionNumber: string;
  gwsSubtotal: string;
  zohoSubtotal: string;
  arpuAmount: string;
  billingMonth: string;
}

export interface CustomerDataBaseContextType {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  customerData: CustomerData[];
  loader: boolean;
  sizeChanger: number;
  currentPage: number;
  totalRecords: number;
  setSizeChanger: React.Dispatch<React.SetStateAction<number>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setTotalRecords: React.Dispatch<React.SetStateAction<number>>;
  csvUploadAPI: (formData: FormData, csvType: string) => Promise<void>;
  syncAPI: () => Promise<void>;
}
export function getYearMonth() {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");

  return `${year}-${month}`;
}
export function formatDate(inputDate: string) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export const transformHubSpotDealsData = (data: any[]) => {
  if (!data || data.length === 0) {
    return [];
  }
  return data.map((item, index) => ({
    sno: index + 1,
    dealName: item?.properties?.dealname ?? "-",
    pipeline: item?.properties?.pipeline ?? "-",
    dealStage: item?.properties?.dealstage ?? "-",
    createdDate: item?.properties?.createdate ? formatDate(item?.properties?.createdate) : "-",
    closeDate: item?.properties?.closedate ? formatDate(item?.properties?.closedate) : "-",
    amount: item?.properties?.amount ?? "-",
  }));
};
