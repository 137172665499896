interface CustomerData {
  arpuID: string;
  domainName: string;
  planNames: string;
  subscriptionNumber: string;
  gwsSubtotal: string;
  zohoSubtotal: string;
  arpuAmount: string;
  billingMonth: string;
}

type ThresholdListType = {
  data?: {
    [key: string]: any;
  };
};

export interface CustomerDataBaseContextType {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  customerData: CustomerData[];
  loader: boolean;
  sizeChanger: number;
  currentPage: number;
  totalRecords: number;
  pageSize: number;
  setSizeChanger: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setTotalRecords: React.Dispatch<React.SetStateAction<number>>;
  csvUploadAPI: (formData: FormData, csvType: string) => Promise<void>;
  syncAPI: () => Promise<void>;
  setSortBy: React.Dispatch<React.SetStateAction<string>>;
  setSortDirection: React.Dispatch<React.SetStateAction<string>>;
  setBillingMonthStartEndDate: React.Dispatch<React.SetStateAction<any>>;
  billingMonthStartEndDate: any
  dateRange: any;
  billingPeriod: { billingMonthStart: string; billingMonthEnd: string };
  selectTab: string;
  thresholdList: ThresholdListType;
  setDateRange: (date: any) => void;
  setBillingPeriod: (period: { billingMonthStart: string; billingMonthEnd: string }) => void;
  setSelectTab: (tab: string) => void;
  getMissingList: (type?: string, billingMonthStart?: string, billingMonthEnd?: string, currentPage?: any, pageSize?: any) => Promise<void>;
}
export function getYearMonth() {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");

  return `${year}-${month}`;
}
export function formatDate(inputDate: string) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  // const day = date.getDate().toString().padStart(2, "0"); will be added later

  return `${year}-${month}`;
}
export const transformCustomerData = (data: any[]) => {
  if (!data || data.length === 0) {
    return [];
  }
  return data.map((item) => ({
    domainName: item.domainName ?? "-",
    quantity:item.quantity ?? "-",
    customerName:item.customerName ?? "-",
    planType:item.planType ??"-",
    planNames: item.planNames ?? "-",
    // subscriptionNumber: item.subscriptionNumber ?? "-",
    gwsSubtotal: item.gwsSubtotal ?? "-",
    zohoSubtotal: item.zohoSubtotal ?? "-",
    arpuAmount: item.arpuAmount ?? "-",
    billingMonth: item.billingMonth ? formatDate(item.billingMonth) : "-",
  }));
};
