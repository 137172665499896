import { useNavigate } from "react-router-dom";
import { useHubSpotDealCreateContext } from "../context/HubSpotDealCreateProvider";
const HubSpotDealCreateHeader = () => {
  const { searchValue, handleSearch } = useHubSpotDealCreateContext();
  const navigate = useNavigate();
  return (
    <div className="...">
      <div className="al-ser1">
        <div>
          <div className="flex px-2 py-2 rounded-md border-2 int w-full	 overflow-hidden  font-[sans-serif]">
            <svg width="18" height="26" viewBox="0 0 24 24" fill="none" className=" w-[14px] xl:w-[18px] h-[18px] xl:h-[26px]" xmlns="http://www.w3.org/2000/svg">
              <g id="vuesax/linear/search-normal">
                <g id="search-normal">
                  <path
                    id="Vector"
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path id="Vector_2" d="M22 22L20 20" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </g>
              </g>
            </svg>

            <input type="text" placeholder="Search" onChange={handleSearch} value={searchValue} className="w-full outline-none bg-transparent text-gray-600 text-[11px] xl:text-[14px] search-input" />
          </div>
        </div>
        <div>
          <button
            className="n-user flex align-center text-[14px]"
            type="button"
            onClick={() => {
              navigate("/hubspot/create");
            }}
          >
            Create Deal
          </button>
        </div>
      </div>
    </div>
  );
};

export default HubSpotDealCreateHeader;
