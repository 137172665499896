import React, { useState, useEffect } from "react";
import img from "../assets/images/logo.png";
import img1 from "../assets/sign.png";
import akku from "../assets/akku.png";
import { useNavigate, useLocation } from "react-router-dom";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { triggerNotification } from "../component/Toster";
import { apiUrl } from "../services/baseUrl";
import { jwtDecode } from "jwt-decode";
import FullPageLoader from "../pages/FullPageLoader";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  useEffect(() => {
    const handleToken = async () => {
      setLoader(true);
      // Get id_token from the URL
      const params = new URLSearchParams(location.search);
      const idToken = params.get("id_token");

      if (idToken) {
        sessionStorage.setItem("idToken", idToken); // Save it in sessionStorage if needed

        try {
          // Decode the token to extract email and other details
          const decodedToken: any = jwtDecode(idToken); // Type 'any' to prevent TypeScript errors, or define a type for expected claims
          const userEmail = decodedToken.email;
          const name = decodedToken.name;

          const userExistenceResponse = await checkUserExist(userEmail);

          if (userExistenceResponse.status === "success") {
            if (userExistenceResponse.data && userExistenceResponse.data[0]) {
              // User exists, proceed with setting session storage and navigation
              sessionStorage.setItem("authToken", idToken);
              sessionStorage.setItem("token", idToken);
              sessionStorage.setItem("userEmail", userEmail);
              sessionStorage.setItem("userName", name);
              sessionStorage.setItem("userID", userExistenceResponse.data[0].user_id);
              sessionStorage.setItem("userRoleId", userExistenceResponse.data[0].role_id);

              navigate("/invoice_logs"); // Navigate to invoice_logs page
              setLoader(false);
            } else {
              setLoader(false);
              triggerNotification("error", "Unauthorized Access", "You do not have permission to access this application.", "topRight");
            }
          } else {
            setLoader(false);
            // User does not exist or there was an error
            triggerNotification("error", "Unauthorized Access", "You do not have permission to access this application.", "topRight");
          }
          setLoader(false);
        } catch (error) {
          console.error("Failed to decode token:", error);
          setLoader(false);
        }
      } else {
        setLoader(false);
      }
    };

    handleToken();
  }, [location.search]);

  // Function to get query parameters
  const getQueryParams = (queryString: any) => {
    const params = new URLSearchParams(queryString);
    return {
      approvalId: params.get("approvalId"),
    };
  };
  const { approvalId } = getQueryParams(location.search);

  useEffect(() => {
    if (approvalId) {
      fetchApprovalData(approvalId);
    }
  }, [approvalId]);

  const fetchApprovalData = async (approvalId: string) => {
    try {
      let url = apiUrl + `pendingApprovalInvoice.php?approvalId=${approvalId}`;

      const response = await fetch(url, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error(`Error fetching approval data: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.data && data.data.length > 0) {
        if (data.data[0].status == "0") {
          let invoice_id = data.data[0].invoice_id;

          const userExistenceResponse = await getSuperAdminDetails();

          if (userExistenceResponse.status === "success") {
            if (userExistenceResponse.data && userExistenceResponse.data[0]) {
              // User exists, proceed with setting session storage and navigation
              sessionStorage.setItem("authToken", "f0bfcc642614ee5735164725298696dbc92303d79d76fa34a43389166f6d6356");
              sessionStorage.setItem("token", "f0bfcc642614ee5735164725298696dbc92303d79d76fa34a43389166f6d6356");
              sessionStorage.setItem("userEmail", "thamotharakannan.g@cloudnowtech.com");
              sessionStorage.setItem("userName", "Thamothara Kannan");
              sessionStorage.setItem("userID", userExistenceResponse.data[0].user_id);
              sessionStorage.setItem("userRoleId", userExistenceResponse.data[0].role_id);

              sessionStorage.editInvoiceID = invoice_id;
              sessionStorage.quoteStatus = "0";
              navigate("/proposal?isEdit=true&status=approve");

              //navigate("/invoice_logs"); // Navigate to invoice_logs page
            } else {
              triggerNotification("error", "Unauthorized Access", "You do not have permission to access this application.", "topRight");
            }
          } else {
            // User does not exist or there was an error
            // console.log('User does not exist or error:', userExistenceResponse.message);
            triggerNotification("error", "Unauthorized Access", "You do not have permission to access this application.", "topRight");
            // Handle the case where user does not exist or there's an error
          }
        }
      }
      // Handle the approval data here (e.g., set state to display it)
    } catch (error: any) {
      console.error("Failed to fetch approval data:", error.message);
    }
  };

  const responseMessage = async (response: any) => {
    console.log(response);
    if (response) {
      if (response.access_token) {
        // const decoded = jwt_decode(response.credential);
        try {
          const userInfoResponse = await fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          });

          const userInfo = await userInfoResponse.json();
          const userEmail = userInfo.email;

          if (userEmail) {
            const userExistenceResponse = await checkUserExist(userEmail);

            if (userExistenceResponse.status === "success") {
              if (userExistenceResponse.data && userExistenceResponse.data[0]) {
                // User exists, proceed with setting session storage and navigation
                sessionStorage.setItem("authToken", response.access_token);
                sessionStorage.setItem("token", response.access_token);
                sessionStorage.setItem("userEmail", userEmail);
                sessionStorage.setItem("userName", userInfo.name);
                sessionStorage.setItem("userID", userExistenceResponse.data[0].user_id);
                sessionStorage.setItem("userRoleId", userExistenceResponse.data[0].role_id);
                console.log("User email:", userEmail);

                navigate("/invoice_logs"); // Navigate to invoice_logs page
              } else {
                triggerNotification("error", "Unauthorized Access", "You do not have permission to access this application.", "topRight");
              }
            } else {
              // User does not exist or there was an error
              // console.log('User does not exist or error:', userExistenceResponse.message);
              triggerNotification("error", "Unauthorized Access", "You do not have permission to access this application.", "topRight");
              // Handle the case where user does not exist or there's an error
            }
          }
        } catch (error) {
          console.error("Failed to fetch user info:", error);
          triggerNotification("error", "Unauthorized Access", "You do not have permission to access this application.", "topRight");
        }
      }
    }
  };
  const getSuperAdminDetails = async () => {
    let url = apiUrl + `getSuperAdminDetails.php`;

    const response = await fetch(url, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error(`Error fetching approval data: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  };
  const checkUserExist = async (email: string) => {
    try {
      const formdata = new FormData();
      formdata.append("email_id", email); // Append email to FormData
      let url = apiUrl + `getUserByID.php`;
      const response = await fetch(url, {
        method: "POST",
        body: formdata, // Use FormData as the body
      });

      const data = await response.json();
      console.log("Check user exist response:", data);

      return data; // Return the response object from the API
    } catch (error) {
      return { status: "error", message: "Error checking user existence" };
    }
  };

  const errorMessage = (error: any) => {
    console.log(error);
  };
  const login = useGoogleLogin({
    onSuccess: responseMessage,
    onError: errorMessage,
    scope: "profile email", // Adjust the scope as needed
  });
  const gotoSSO = () => {
    window.location.href = "https://dev-gwscalculater.cloudnowtech.com/test";
  };
  return loader ? (
    <FullPageLoader />
  ) : (
    <>
      <div className="login">
        <div className="max-w-screen-2xl mx-auto">
          <div className="login-page mb-4">
            <div>
              <img className="h-8 w-auto" src={img} alt="Your Company" />
            </div>
            <div className="flex gap-6 justify-between items-center">
              <div>
                <h1>
                  One click sign in
                  <br />
                  with Google
                </h1>
              </div>

              <div>
                {/* <a className="google" href=""> 
                  <img className="w-auto" src={img1} alt="Your Company" />
                  Sign in with Google
                </a> */}
                <button className="google" onClick={(e) => login()}>
                  <img className="w-auto" src={img1} alt="Your Company" />
                  Sign in with Google
                </button>
                {/* <button className="akku" onClick={(e) => gotoSSO()}>
                    <img className="w-auto" src={akku} alt="Your Company" style={{ width: 50 }} />
                    Sign in with Akku
                  </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
