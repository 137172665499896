import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InvoiceState {
  approve: boolean | null; 
}

const initialState: InvoiceState = {
  approve: null,
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    setQuoteApprovals(state, action: PayloadAction<boolean | null>) {
      state.approve = action.payload;
    },
  
    getQuoteApprovals(state):  any {
      return state.approve;
    },
  },
});

export const { setQuoteApprovals, getQuoteApprovals } = invoiceSlice.actions;

export default invoiceSlice.reducer;
