import React, { useContext } from "react";
import { HubSpotDealCreateContext, HubSpotDealCreateProvider } from "./HubSpotDealCreateContext";
import HubSpotDealCreate from "../HubSpotDeals";

const HubSpotDealCreateWrapper = () => {
  return (
    <HubSpotDealCreateProvider>
      <HubSpotDealCreate />
    </HubSpotDealCreateProvider>
  );
};

export default HubSpotDealCreateWrapper;

export const useHubSpotDealCreateContext = () => {
  const context = useContext(HubSpotDealCreateContext);
  if (!context) {
    throw new Error("useHubSpotDealCreateContext must be used within HubSpotDealCreateContext");
  }
  return context;
};
