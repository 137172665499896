import React, { ReactNode } from "react";
import { Layout } from "antd";
import { Link, Outlet } from "react-router-dom";
import img from "../../assets/images/logo.png";

// Define props type for the layout to include children
interface BeforeLoginLayoutProps {
  children?: ReactNode;
}

const BeforeLoginLayout: React.FC<BeforeLoginLayoutProps> = ({ children }) => {
  return <Outlet />;
};

export default BeforeLoginLayout;
