import React, { useContext } from "react";
import { CustomerDataBaseContext, CustomerDataBaseProvider } from "./CustomerDataBaseContext";
import CustomerDatabase from "../CustomerDatabase";

const CustomerDataBaseWrapper = () => {
  return (
    <CustomerDataBaseProvider>
      <CustomerDatabase />
    </CustomerDataBaseProvider>
  );
};

export default CustomerDataBaseWrapper;

export const useCustomerDataBaseContext = () => {
  const context = useContext(CustomerDataBaseContext);
  if (!context) {
    throw new Error("useCustomerDataBaseContext must be used within CustomerDataBaseContext");
  }
  return context;
};
